<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3>Get Instant Quote</h3>
            <p>
              Life is hard enough without having to worry about getting
              insurance.
            </p>
          </div>
          <div class="col-lg-3">
            <a
              href="#"
              @click.prevent="goto('GetYourQuote')"
              class="bllutButton"
              >Enter Zip Code</a
            >
            <!-- <div class="form-group">
              <input
                type="number"
                v-model="zip"
                class="zip"
                placeholder="Enter Zip Code"
                min="0"
                max="99999"
              />
            </div> -->
          </div>
          <div class="col-lg-3">
            <a href="#" @click.prevent="goto('GetYourQuote')" class="RedButton"
              >Get A Quote</a
            >
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetInstantQuote',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.zip {
  background-color: #ffffff;
  border: 0px;
  height: 40px;
  width: 200px;
  margin-top: 0px;
  border-radius: 10px;
}
.bllutButton {
  color: #114d90;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  animation: 1s ease-in 0s 1 slideInFromLeft;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  font-size: 30px;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
    0px -4px 5px rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  background: #ffffff;
  text-decoration: none;
  padding: 15px 30px;
  display: inline-block;
  margin-top: 20px;
}
.RedButton {
  color: #fff;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  animation: 1.2s ease-in 0s 1 slideInFromLeft;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  font-size: 30px;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
    0px -4px 5px rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  background: #f40f0f;
  text-decoration: none;
  padding: 15px 30px;
  display: inline-block;
  margin-top: 20px;
}
.mTop {
  padding-top: 20px;
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  background-image: url('@/assets/giq.jpg');
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
  padding-top: 40px;
  h3 {
    color: #fffefe;
    font-family: 'Open Sans', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 142.2%; /* 51.192px */
    letter-spacing: -0.18px;
  }
  h4 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
  }
  h6 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 240%;
  }
  p {
    color: white !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 139.7%; /* 27.94px */
    letter-spacing: 0.4px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 992px) {
  .RightDeals {
    background: #f5f5f5;
    background-image: url('@/assets/getbgm.jpg');
    background-size: cover;
    background-position: center;
    padding-bottom: 40px;
    padding-top: 30px;
  }
  .bllutButton {
    margin-top: 0px;
    font-size: 16px !important;
    padding: 10px 20px;
  }
  .RedButton {
    margin-top: 10px;
    font-size: 16px !important;
    padding: 10px 20px;
  }
  .mainHeading {
    font-size: 32px;
  }
  .mTop {
    width: 300px;
    padding-top: 0px;
    svg {
      width: 50px;
      height: 50px;
    }
    h6 {
      font-size: 18px;
      line-height: 140%;
    }
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>
