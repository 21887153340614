<template>
  <div class="container-flued homeSlide1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 leftSide">
          <p class="fcolor text-justify">
            At Ever Medicare, our goal is to help you provide the most
            affordable health insurance. As we are committed to fostering
            enduring mutual trust, we pledge to protect the privacy of your
            personal information. It is essential that you acknowledge how our
            website(s) gather and handle your information, as well as the
            measures we put in place to protect your privacy. Please read this
            carefully, keeping in mind that this Privacy Policy solely applies
            to this website.
          </p>
          <h4 class="fcolor">Information We Collect:</h4>
          <p class="fcolor text-justify">
            This website is used by Ever Medicare to gather personally
            identifiable data. By using our service, you give Ever Medicare
            permission to share your query and personal data. The personal data
            that we gather could consist of, but is not restricted to Name, Age,
            Sex, Address, Email, Phone Number and Health Information.
          </p>
          <h4 class="fcolor">How We Collect Information:</h4>
          <p class="fcolor text-justify">
            Visitors to this website may get cookies and other technologies,
            such as web beacons or tags. "Cookies" are text-only packets of
            information that a website sends to a user's hard drive or other
            web-browsing device for record-keeping. Cookies allow the website to
            remember crucial information, making future visits more convenient.
            A cookie normally contains the name of the domain from which it
            originated, the cookie's "lifetime," and a randomly generated,
            unique number or other value.
          </p>
          <p class="fcolor text-justify">
            A "beacon," "tag," or "tracking pixel" is a one-pixel-by-one-pixel
            picture that is attached to an online item or an email. When your
            browser connects to a web page that contains a web beacon picture,
            it sends a request to the web server to download the image — and the
            server logs information such as your IP address, date and time, and
            so on.
          </p>
          <p class="fcolor text-justify">
            Cookies, web beacons, and other technologies collect and use
            information anonymously, allowing no third party to identify you
            individually.
          </p>

          <h4 class="fcolor">Log files:</h4>
          <p class="fcolor text-justify">
            Internet protocol (IP) addresses, browser types, Internet Service
            Providers (ISPs), date and time stamps, referring/exit pages, and
            maybe click counts are among the data gathered by log files. These
            are not connected to any personally identifiable information. The
            data is gathered with the intention of evaluating patterns, managing
            the website, monitoring user behavior, and compiling demographic
            data.
          </p>
          <h4 class="fcolor">How We Use Your Information:</h4>
          <p class="fcolor text-justify">
            We will provide your selected insurance provider with your personal
            information so they can process your application for an insurance
            policy.
          </p>
          <p class="fcolor text-justify">
            We may share your personal information with an insurance agent or
            agency we work with if you apply for life insurance so they can
            process your application and give the estimate you requested.
          </p>
          <p class="fcolor text-justify">
            Rest assured that your personal information will only be used by the
            agent or agency to fulfill your request for an estimate or
            application, and that they are legally obligated to maintain the
            strictest confidentiality regarding your personal information. We
            won't give your personal information to outside parties unless the
            aforementioned situations apply.
          </p>
          <p class="fcolor text-justify">
            Your personal information is secure and kept private by us. We
            implement procedural, electronic, and physical safeguards to secure
            your personal data. For a more thorough explanation of the technical
            safeguards on our website, please see the remark.
          </p>
          <p class="fcolor text-justify">
            We may get in touch with you to find out how satisfied you are with
            our services and/or to inform you of our newest products and
            services. Please send us an email if you would like to stop getting
            these surveys and/or notifications.
          </p>
          <h4 class="fcolor">Contacting Us:</h4>
          <p class="fcolor text-justify">
            If you have any concerns concerning Ever Medicare, our website, our
            service, or this Privacy Policy, or if your personally identifiable
            information changes or you no longer want to use our services,
            please contact us using the information provided below.
          </p>
          <h4 class="fcolor">500 Rahway Avenue Elizabeth, NJ 07202</h4>
          <h4 class="fcolor">+1 (201) 285 5090</h4>
          <h4 class="fcolor">admin@evermedicare.com</h4>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-flued homeSlide1">
    <div class="row">
      <div class="col-12">
        <h2>"Stay Prepared, Stay Protected</h2>
        <h5>Insurance for Life's Uncertainties"</h5>
        <h6>Your Shield in an Uncertain World"</h6>
        <a href="#" class="yellowButton">Get Your Quote</a>
      </div>
    </div>
  </div> -->
  <!--container-->
</template>

<script>
export default {
  name: 'PrivacyDesc',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.homeSlide1 {
  background: rgba(242, 242, 242, 0.6);
  padding-bottom: 40px;
  .leftSide {
    padding-top: 40px;
    h2 {
      color: #f5f5f5;
      font-family: Inter;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2),
        0px -4px 5px rgba(255, 255, 255, 0.2);
      margin-top: 40px;
      animation: 0.5s ease-in 0s 1 slideInFromLeft;
      text-align: center;
    }
    h5 {
      color: #292341;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      /* animation: 0.7s ease-in 0s 1 slideInFromLeft; */
    }
    p {
      color: #292341;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      a {
        color: rgba(226, 184, 66, 0.91);
      }
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 100%;
        /* filter: drop-shadow(0 0 0.75rem #00000099); */
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #ffc93c;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
ul {
  li {
    font-weight: 400;
  }
}
@media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1300px) {
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
      }
      h5 {
        font-size: 18px;
      }
      h6 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      .nav-item {
        width: 20%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #242f9b !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffc93c;
  }

  .navbar-collapse {
    background-color: #242f9bee;
    padding-left: 10px;
    margin-top: 21px;
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #242f9b !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
        margin-top: 40px;
      }
      h5 {
        font-size: 18px;
        width: 100%;
        /* text-align: center; */
      }
      h6 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h2 {
      font-size: 32px;
      margin-top: 40px;
    }
    h5 {
      font-size: 32px;
      width: 100%;
      /* text-align: center; */
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
}
</style>
