<template>
  <div class="container-flued homeSlide1">
    <div class="container">
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8 leftSide">
          <p>
            {{ heading }}
          </p>
        </div>
        <div class="col-lg-2"></div>
      </div>
    </div>
  </div>
  <!-- <div class="container-flued homeSlide1">
    <div class="row">
      <div class="col-12">
        <h2>"Stay Prepared, Stay Protected</h2>
        <h5>Insurance for Life's Uncertainties"</h5>
        <h6>Your Shield in an Uncertain World"</h6>
        <a href="#" class="yellowButton">Get Your Quote</a>
      </div>
    </div>
  </div> -->
  <!--container-->
</template>

<script>
export default {
  name: 'PageHeading',
  props: {
    heading: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.homeSlide1 {
  background: linear-gradient(135deg, #3b3554 1%, #6c687c 40%, #afabb4 100%);
  padding-bottom: 40px;
  .leftSide {
    h2 {
      color: #f5f5f5;
      font-family: Inter;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2),
        0px -4px 5px rgba(255, 255, 255, 0.2);
      margin-top: 40px;
      animation: 0.5s ease-in 0s 1 slideInFromLeft;
      text-align: center;
    }
    h5 {
      color: #f5f5f5;
      font-family: Futura Hv BT;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      animation: 0.7s ease-in 0s 1 slideInFromLeft;
      font-family: Inter;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 50px;
      text-align: center;
      text-shadow: 3px 0px 7px rgba(81, 67, 21, 0.8),
        -3px 0px 7px rgba(81, 67, 21, 0.8), 0px 4px 7px rgba(81, 67, 21, 0.8);
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 100%;
        /* filter: drop-shadow(0 0 0.75rem #00000099); */
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #ffc93c;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
@media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1300px) {
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
      }
      h5 {
        font-size: 32px;
      }
      h6 {
        font-size: 24px;
      }
      p {
        font-size: 40px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      .nav-item {
        width: 20%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #242f9b !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffc93c;
  }

  .navbar-collapse {
    background-color: #242f9bee;
    padding-left: 10px;
    margin-top: 21px;
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #242f9b !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
        margin-top: 40px;
      }
      h5 {
        font-size: 48px;
        width: 100%;
        /* text-align: center; */
      }
      h6 {
        font-size: 32px;
      }
      p {
        font-size: 32px;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h2 {
      font-size: 32px;
      margin-top: 40px;
    }
    h5 {
      font-size: 32px;
      width: 100%;
      /* text-align: center; */
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
}
</style>
