<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-12">
            <div class="whiteBar"></div>
          </div> -->
          <div class="col-lg-3"></div>
          <div class="col-lg-6 formBox">
            <h3>Get Your Medicare Quote</h3>
            <div class="whiteBar"></div>
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Enter Your Zip Code:</label>
                <input
                  type="number"
                  class="form-control"
                  id="zip"
                  aria-describedby="emailHelp"
                  placeholder="Enter Zip"
                  v-model="zip"
                />
                <div class="alert alert-danger" v-if="zipErrorMsg !== ''">
                  {{ zipErrorMsg }}
                </div>
                <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Date of Birth</label>
                <div class="row selectDOB">
                  <select
                    class="form-control fc33"
                    id="exampleFormControlSelect1"
                    v-model="dd"
                  >
                    <option>dd</option>
                    <option v-for="item in 31" :key="item">{{ item }}</option>
                  </select>
                  <select
                    class="form-control fc33"
                    id="exampleFormControlSelect1"
                    v-model="mm"
                  >
                    <option>mm</option>
                    <option v-for="item in 12" :key="item">{{ item }}</option>
                  </select>
                  <select
                    class="form-control fc33"
                    id="exampleFormControlSelect1"
                    v-model="yy"
                  >
                    <option>yy</option>
                    <option v-for="item in yearRange" :key="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <!-- <div class="alert alert-danger" v-if="nameErrorMsg !== ''">
                  {{ nameErrorMsg }}
                </div> -->
                <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
              </div>
              <div class="row">
                <div class="form-group">
                  <label for="exampleInputEmail1">Gender:</label>
                </div>
                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="male"
                      v-model="gender"
                      checked
                    />
                    <label class="form-check-label" for="exampleRadios1">
                      <img src="@/assets/male.png" />Male
                    </label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      value="female"
                      v-model="gender"
                    />
                    <label class="form-check-label" for="exampleRadios2">
                      <img src="@/assets/female.png" />Female
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="first_name">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="first_name"
                  v-model="first_name"
                  aria-describedby="emailHelp"
                  placeholder="Enter First Name"
                />
                <div class="alert alert-danger" v-if="firstNameErrorMsg !== ''">
                  {{ firstNameErrorMsg }}
                </div>
                <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
              </div>
              <div class="form-group">
                <label for="last_name">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="last_name"
                  v-model="last_name"
                  placeholder="Enter Last Name"
                />
                <div class="alert alert-danger" v-if="lastNameErrorMsg !== ''">
                  {{ lastNameErrorMsg }}
                </div>
              </div>
              <div class="form-group">
                <label for="phoe">Phone:</label>
                <input
                  type="number"
                  class="form-control"
                  id="phone"
                  placeholder="Enter Phone"
                  v-model="phone"
                />
                <div class="alert alert-danger" v-if="phoneErrorMsg !== ''">
                  {{ phoneErrorMsg }}
                </div>
              </div>
              <!-- <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >Check me out</label
                >
              </div> -->
              <div class="form-group">
                <div class="alert alert-success" v-if="apiResponse !== ''">
                  {{ apiResponse }}
                </div>
                <button
                  type="submit"
                  @click.prevent="validate()"
                  class="btn btn-primary"
                >
                  Get Your Quote Now
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-3"></div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'GaqForm',
  props: {
    msg: String,
  },
  data() {
    return {
      zip: '',
      mm: '',
      dd: '',
      yy: '',
      first_name: '',
      last_name: '',
      gender: '',
      phone: '',
      yearRange: [],
      zipErrorMsg: '',
      apiResponse: '',
      phoneErrorMsg: '',
      firstNameErrorMsg: '',
      lastNameErrorMsg: '',
    };
  },
  mounted() {
    const arrayRange = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
      );
    this.yearRange = arrayRange(1900, 2024, 1);
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
    validate() {
      let error = 0;
      if (this.zip == '') {
        error = error + 1;
        this.zipErrorMsg = 'Zip is required.';
        document.getElementById('zip').style.borderBottom = '1px solid red';
        document.getElementById('zip').style.borderRadius = '0px';
      } else {
        this.zipErrorMsg = '';
        document.getElementById('zip').style.borderBottom = '1px solid #114d90';
        document.getElementById('zip').style.borderRadius = '0px';
      }
      if (this.first_name == '') {
        error = error + 1;
        this.firstNameErrorMsg = 'First Name is required.';
        document.getElementById('first_name').style.borderBottom =
          '1px solid red';
        document.getElementById('first_name').style.borderRadius = '0px';
      } else {
        this.firstNameErrorMsg = '';
        document.getElementById('first_name').style.borderBottom =
          '1px solid #114d90';
        document.getElementById('first_name').style.borderRadius = '0px';
      }
      if (this.last_name == '') {
        error = error + 1;
        this.lastNameErrorMsg = 'Last Name is required.';
        document.getElementById('last_name').style.borderBottom =
          '1px solid red';
        document.getElementById('last_name').style.borderRadius = '0px';
      } else {
        this.lastNameErrorMsg = '';
        document.getElementById('last_name').style.borderBottom =
          '1px solid #114d90';
        document.getElementById('last_name').style.borderRadius = '0px';
      }
      if (this.phone == '') {
        error = error + 1;
        this.phoneErrorMsg = 'Phone is required.';
        document.getElementById('phone').style.borderBottom = '1px solid red';
        document.getElementById('phone').style.borderRadius = '0px';
      } else {
        this.phoneErrorMsg = '';
        document.getElementById('phone').style.borderBottom =
          '1px solid #114d90';
        document.getElementById('phone').style.borderRadius = '0px';
      }

      if (error == 0) {
        axios
          .post('https://evermedicare.com/saveMedicarePlane.php', {
            zip: this.zip,
            dd: this.dd,
            mm: this.mm,
            yy: this.yy,
            first_name: this.first_name,
            last_name: this.last_name,
            gender: this.gender,
            phone: this.phone,
          })
          .then(response => {
            console.log(response.data);
            if (response.data == 'success') {
              this.apiResponse =
                'Thanks for contacting us. We will reach to you soon!';
            }
            //this.apiResponse = response.data;
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.alert-success {
  color: green;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.form-check-input:checked {
  background-color: #114d90;
  border-color: #114d9099;
}
.form-check-input {
  margin-top: 30px;
}
label {
  img {
    height: 80px;
  }
}
.addressInfo {
  border-radius: 8px 8px 0 0;
  background: linear-gradient(180deg, #2e38b0 0%, rgba(109, 115, 189, 0) 100%);
  height: 220px;
  h5 {
    text-align: center;
    margin-top: 40px;
    width: 100%;
  }
  h6 {
    width: 350px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #114d90;
    svg {
      margin-right: 20px;
    }
  }
}
.whiteBar {
  width: 120px;
  border-bottom: 4px solid white;
  margin: 0 auto;
  margin-bottom: 20px;
}
.RightDeals {
  background: #ffffff;
  background: linear-gradient(
    -45deg,
    #114d9020,
    #114d9000,
    #114d9015,
    #114d9000
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding-bottom: 40px;
  padding-top: 40px;

  .formBox {
    background-color: #ffffff99;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    padding: 30px;
  }
  .form-group {
    margin-top: 20px;
    .fc33 {
      width: 32%;
      float: left;
    }
    label {
      color: #114d90;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    input {
      background: #ffffff;
      border: 0px;
      border: 1px solid #114d90;
      color: #114d90;
      border-radius: 0px;
    }
    select {
      background: #ffffff;
      border: 0px;
      border: 1px solid #114d90;
      color: #114d90;
      border-radius: 0px;
      margin-left: 1%;
    }
    textarea {
      background: #ffffff;
      border: 0px;
      border-bottom: 1px solid #114d90;
      color: #114d90;
      border-radius: 0px;
    }
    .alert {
      color: red;
      font-weight: 300;
      padding: 5px 10px;
      border-radius: 0px;
    }
  }
  .form-control:focus {
    border-color: rgba(226, 184, 66, 0.91);
    outline: 0;
    box-shadow: none;
  }
  .btn-primary {
    background-color: #114d90;
    border-color: #114d90;
    width: 100%;
    color: #ffffff;
    margin-bottom: 80px;
  }
  h3 {
    color: #114d90;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 40px;
  }
  h4 {
    color: #292341;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  h5 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: inline-block;
    margin-left: 20px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #292341;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 16px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 24px;
    }
    h5 {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 992px) {
  .RightDeals {
    .formBox {
      box-shadow: none;
      padding: 20px;
      background-color: #ffffff01;
    }
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
@media screen and (max-width: 540px) {
  .RightDeals {
    .form-group {
      input {
        border: 1px solid #114d90;
        color: #114d90;
        border-radius: 4px;
      }
      select {
        border: 1px solid #114d90;
        color: #114d90;
        border-radius: 5px;
        margin-left: 1%;
      }
      textarea {
        background: #ffffff;
        border: 0px;
        border-bottom: 1px solid #114d90;
        color: #114d90;
        border-radius: 0px;
      }
    }
  }
  .selectDOB {
    margin-left: 1%;
    margin-right: 1%;
  }
}
</style>
