<template>
  <div class="container-flued homeSlide1">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 leftSide">
          <h2>
            Get The Medicare<br />
            Coverage You Need<br />
            To Stay Healthy
          </h2>
          <!-- <section class="animation">
            <div class="first"><div>technology</div></div>
            <div class="second"><div>Innovation</div></div>
            <div class="third"><div>Automation</div></div>
          </section> -->

          <h5>
            <!-- Trust your healthcare needs to a<br /> -->
            Medicare plan that puts you first
          </h5>
          <a href="#" @click.prevent="goto('GetYourQuote')" class="bllutButton"
            >Enter Zip Code</a
          >
          <a href="#" @click.prevent="goto('GetYourQuote')" class="RedButton"
            >Get A Quote</a
          >
          <!-- <div class="row">
            <div class="col-lg-6 grayBoxContainer">
              <div class="grayBox">
                <div class="orangeBox">
                  <img src="@/assets/bicon1.png" />
                </div>
                <div class="grayBoxText">Information Technology</div>
              </div>
            </div>
            <div class="col-lg-6 grayBoxContainer">
              <div class="grayBox">
                <div class="orangeBox">
                  <img src="@/assets/bicon2.png" />
                </div>
                <div class="grayBoxText">Business Development</div>
              </div>
            </div>
            <div class="col-lg-6 grayBoxContainer">
              <div class="grayBox">
                <div class="orangeBox">
                  <img src="@/assets/bicon3.png" />
                </div>
                <div class="grayBoxText">Much More</div>
              </div>
            </div>
          </div> -->

          <!-- <a href="#" class="yellowButton">Get Your Quote</a> -->
        </div>
        <div class="col-lg-4 rightSide">
          <div class="row">
            <div class="col-lg-12 circle1">
              <!-- <TransitionGroup name="list" tag="ul">
                <img
                  v-if="coloudImgNoOne"
                  src="@/assets/pic2.png"
                  class="slideImg"
                />
                <img
                  v-if="coloudImgNoTwo"
                  src="@/assets/slider2.png"
                  class="slideImg"
                />
              </TransitionGroup> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-flued homeSlide1">
    <div class="row">
      <div class="col-12">
        <h2>"Stay Prepared, Stay Protected</h2>
        <h5>Insurance for Life's Uncertainties"</h5>
        <h6>Your Shield in an Uncertain World"</h6>
        <a href="#" class="yellowButton">Get Your Quote</a>
      </div>
    </div>
  </div> -->
  <!--container-->
</template>

<script>
export default {
  name: 'HomeSlider',
  data() {
    return {
      cloudImg: require('@/assets/pic2.png'),
      coloudImgNoOne: true,
      coloudImgNoTwo: false,
    };
  },
  mounted() {
    var cThis = this;
    window.setInterval(() => {
      if (cThis.coloudImgNoOne == true) {
        cThis.coloudImgNoOne = false;
        setTimeout(function () {
          cThis.coloudImgNoTwo = true;
        }, 510);
      } else {
        cThis.coloudImgNoTwo = false;
        setTimeout(function () {
          cThis.coloudImgNoOne = true;
        }, 510);
      }
    }, 5000);
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.list-enter-active {
  transition: all 0.5s ease;
  /* transition-delay: 0.5s; */
}
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from {
  opacity: 0;
  transform: translateX(-300px) scale(0.8);
  /* transition-delay: 1s; */
}
.list-leave-to {
  opacity: 0;
  transform: translateX(300px);
}
.grayBoxContainer {
  padding: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.grayBox {
  border-radius: 18px;
  background: #070d5915;
  height: 60px;
  padding: 10px;
}
.orangeBox {
  border-radius: 10px;
  background: #f46700cc;
  height: 40px;
  width: 40px;
  float: left;
  img {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
  }
}
.grayBoxText {
  color: #070d59;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  padding-left: 10px;
  float: left;
}
.animation {
  height: 70px;
  overflow: hidden;
}

.animation > div > div {
  padding: 0.25rem 0.75rem;
  /* height: 2.81rem; */
  margin-bottom: 2.81rem;
  display: inline-block;
  text-transform: uppercase;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}
.first div {
  background-color: #3798d4;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #ffffff;
}
.second div {
  background-color: #070d59;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #ffffff;
}
.third div {
  background-color: #f46700;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #ffffff;
}
@keyframes text-animation {
  0% {
    margin-top: 0;
  }
  10% {
    margin-top: 0;
  }
  20% {
    margin-top: -48px;
  }
  30% {
    margin-top: -48px;
  }
  40% {
    margin-top: -125px;
  }
  60% {
    margin-top: -125px;
  }
  70% {
    margin-top: -48px;
  }
  80% {
    margin-top: -48px;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}
.homeSlide1 {
  /* background: linear-gradient(
    99deg,
    #292341 -5.28%,
    rgba(0, 52, 120, 0.26) 149.21%
  );
   */
  position: relative;
  display: flex;
  padding-top: 70px;
  padding-bottom: 130px;
  overflow: hidden;
}
.homeSlide1::before {
  content: '';
  background-image: url('@/assets/homehbg.jpg');
  background-size: cover;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* opacity: 0.2;
  animation-name: MOVE-BG;
  animation-duration: 25s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; */
}
@keyframes MOVE-BG {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(0);
  }
}
h2 {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 128.473%; /* 82.223px */
  letter-spacing: 1.6px;
  /* text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
    0px -4px 5px rgba(255, 255, 255, 0.2); */
  margin-top: 20px;
  animation: 0.5s ease-in 0s 1 slideInFromLeft;
  position: relative;
}
h5 {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  animation: 0.7s ease-in 0s 1 slideInFromLeft;
  font-family: 'Open Sans', sans-serif;
  padding-top: 5px;
  position: relative;
  font-size: 24px;
  margin-top: 10px;
  /* text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
    0px -4px 5px rgba(255, 255, 255, 0.2); */
  margin-bottom: 30px;
}
.bllutButton {
  color: #114d90;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  animation: 1s ease-in 0s 1 slideInFromLeft;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  font-size: 30px;
  /* text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
    0px -4px 5px rgba(255, 255, 255, 0.2); */
  border-radius: 13px;
  background: #ffffff;
  text-decoration: none;
  padding: 15px 30px;
}
.RedButton {
  color: #fff;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  animation: 1.2s ease-in 0s 1 slideInFromLeft;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  font-size: 30px;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
    0px -4px 5px rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  background: #f40f0f;
  text-decoration: none;
  padding: 15px 30px;
  display: inline-flex;
  margin-left: 20px;
}
p {
  color: #f5f5f5;
  font-family: Futura Bk BT;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  animation: 0.9s ease-in 0s 1 slideInFromLeft;
  font-family: Inter;
  position: relative;
}
.leftSide {
  .row {
    margin-right: 0px;
  }
  .blueButton {
    /* width: 220px;
    margin: 0 auto;
    text-align: center;
    opacity: 1;
    margin-top: 40px;
    margin-bottom: 50px;
    background-color: red;
    animation: 1.1s ease-in 0s 1 slideInFromLeft; */
    background-color: red;
  }
}
.blueButton {
  background-color: #070d59;
  display: block;
}
.circle1 {
  animation: 0.8s ease-in 0s 1 slideInFromLeft;
  margin-top: 80px;
  position: relative;
}
.slideImg {
  width: 100%;
  position: relative;
  filter: drop-shadow(0 0 0.75rem rgb(182, 182, 182));
}

@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* @media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
} */
@media screen and (max-width: 1200px) {
  h2 {
    font-size: 48px;
  }
  h5 {
    font-size: 32px;
  }
  h6 {
    font-size: 24px;
  }
}

@media screen and (max-width: 992px) {
  .homeSlide1 {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .homeSlide1::before {
    content: '';
    background-image: url('@/assets/homehbgmm.jpg');
    background-size: cover;
    background-position: right;
    width: 100%;
    position: absolute;
  }
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffc93c;
  }
  h2 {
    font-size: 48px;
    margin-top: 40px;
  }
  h5 {
    font-size: 24px;
    width: 100%;
    /* text-align: center; */
  }
  h6 {
    font-size: 32px;
  }
  .circle1 {
    width: 100%;
    img {
    }
  }
  .slideImg {
    width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .first div,
  .second div,
  .third div {
    font-size: 24px;
  }
  /* .homeSlide1::before {
    background-image: url('@/assets/homehbg.jpg');
    opacity: 0.2;
  } */
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }

  h2 {
    font-size: 24px;
    margin-top: 40px;
  }
  h5 {
    font-size: 20px;
    width: 100%;
    /* text-align: center; */
  }
  h6 {
    font-size: 24px;
  }
  .slideImg {
    width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .bllutButton {
    margin-top: 40px;
    padding: 10px 20px;
    font-size: 14px !important;
    border-radius: 5px;
    margin-left: 10px;
  }
  .RedButton {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 14px !important;
    border-radius: 5px;
    margin-left: 10px;
  }
}
</style>
