<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h4>What is covered by Medigap Plans</h4>
            <p calss="BPara">
              Some expenses not covered by Original Medicare are covered by
              Medicare Supplement insurance. A Medigap plan, for instance, might
              pay the 20% coinsurance charge for a doctor visit. Standard
              services must be included in all Medicare Supplemental insurance
              policies. With certain plans, additional perks are offered.
            </p>
            <div class="row">
              <div class="col-12">
                <ul>
                  <li>
                    Every Medicare Supplement insurance policy must include:
                  </li>
                  <li>
                    Up to an additional 365 days of Medicare benefits are
                    subject to Part A coinsurance fees, as well as coinsurance
                    or copayments for hospice care.
                  </li>
                  <li>Medicare Part B copayments or coinsurance</li>
                  <li>Three quarts or more of blood</li>
                  <li>
                    Depending on the plan, additional services are covered:
                  </li>
                  <li>Coinsurance for skilled nursing facility care</li>
                  <li>Deductible in Part A</li>
                  <li>Excess charges for Part B</li>
                  <li>Travel emergency care abroad</li>
                  <li>
                    In general, Medicare Supplement plans exclude coverage for:
                  </li>
                  <li>
                    In general, Medicare Supplement plans exclude coverage for:
                  </li>
                  <li>Drugs on prescription</li>
                  <li>Vision</li>
                  <li>Dental</li>
                  <li>Long-term care</li>
                  <li>Hearing aids</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicarePartA',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subUl {
  margin-left: 20px;
}
ul {
  list-style-type: decimal;
  li {
    font-weight: 300;
    color: #0a172f;
    font-size: 16px;
    line-height: 180%;
  }
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  h5 {
    color: #114d90;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 16px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
