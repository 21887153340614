<template>
  <div class="GameChanger">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-3">
                <div class="blueCircle">
                  <h6>Medicare</h6>
                  <h5>Part A</h5>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="blueCircle">
                  <h6>Medicare</h6>
                  <h5>Part B</h5>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="blueCircle">
                  <h6>Medicare</h6>
                  <h5>Part C</h5>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="blueCircle">
                  <h6>Medicare</h6>
                  <h5>Part D</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutCircles',
  props: {
    msg: String,
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.GameChanger {
  background: linear-gradient(to right, #114d9030 0%, #114d9010 100%);
  padding-bottom: 40px;
  padding-top: 0px;
  .blueCircle {
    background: #114d90;
    height: 200px;
    width: 200px;
    margin: 0 auto;
    border-radius: 50%;
    margin-top: 40px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    h6 {
      font-size: 24px;
      color: #ffffff;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      padding-top: 70px;
      padding-bottom: 0px;
      margin: 0px;
    }
    h5 {
      font-size: 30px;
      color: #ffffff;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      padding-top: 0px;
      margin: 0px;
    }
  }
  .blueCircle:hover {
    background-color: #114d9010;
    transition-duration: 0.8s;
    cursor: pointer;
    h6 {
      color: #114d90;
    }
    h5 {
      color: #114d90;
    }
  }
}

@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
}
@media screen and (max-width: 540px) {
  .GameChanger {
    padding-top: 30px;
    .blueCircle {
      width: 150px;
      height: 150px;
      margin-top: 10px;
      h6 {
        font-size: 18px;
        padding-top: 50px;
      }
      h5 {
        font-size: 24px;
      }
    }
  }
}
</style>
