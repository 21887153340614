<template>
  <div class="blueCard">
    <h5>Compare Plans Today</h5>
    <a href="#" @click.prevent="goto('GetYourQuote')" class="bllutButton"
      >Enter Zip Code</a
    ><br />
    <a href="#" @click.prevent="goto('GetYourQuote')" class="RedButton"
      >Get A Quote</a
    >
    <h6>Or</h6>
    <p class="blueCardP">Call Our Liscenced Agent Now</p>
    <h3>
      <a class="aTell" href="tel:2012855358">
        <svg
          width="29"
          height="40"
          viewBox="0 0 29 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.2323 39.8466C20.3975 38.7542 16.8484 36.8577 13.585 34.157C10.3216 31.4562 7.595 28.3395 5.40531 24.8066C3.21562 21.2738 1.66849 17.4923 0.763924 13.4622C-0.140645 9.43203 -0.170322 5.55198 0.674891 1.82201C0.793031 1.30144 1.06984 0.918434 1.50532 0.673001C1.94081 0.427569 2.42609 0.381066 2.96118 0.533492L10.1848 2.59125C10.601 2.7098 10.943 2.94579 11.211 3.29923C11.4789 3.65267 11.5966 4.0403 11.5642 4.46213L11.3453 10.8658C11.3128 11.2876 11.2214 11.6544 11.0708 11.9662C10.9203 12.2781 10.6825 12.5256 10.3573 12.7089L5.11184 15.7408C5.88781 18.1788 7.00545 20.5755 8.46476 22.931C9.92407 25.2865 11.6324 27.4513 13.5897 29.4252L18.7066 26.5414C19.0333 26.3573 19.4271 26.2619 19.8881 26.2553C20.3492 26.2487 20.7807 26.3329 21.1829 26.5078L27.0607 29.4753C27.4572 29.6807 27.7547 29.9735 27.9531 30.354C28.1516 30.7345 28.2051 31.1266 28.1135 31.5303L26.5186 38.5581C26.4004 39.0786 26.1236 39.4616 25.6881 39.7071C25.2527 39.9525 24.7674 39.999 24.2323 39.8466Z"
            fill="white"
          />
        </svg>
        (201)-285-5358</a
      >
    </h3>
  </div>
</template>

<script>
export default {
  name: 'ComparePlans',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.aTell {
  color: #ffffff !important;
  text-decoration: none;
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 180px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h4 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 20px;
    }
    h5 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-weight: 800;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .bllutButton {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 540px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h4 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 20px;
    }
    h5 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-weight: 800;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .blueCard {
    padding: 10px;
    padding-top: 60px;
    padding-bottom: 40px;
    .bllutButton {
      font-size: 16px !important;
      padding: 7px 20px;
      margin-top: 20px;
      width: 170px;
      font-weight: 800;
    }
    .RedButton {
      font-size: 16px !important;
      padding: 7px 20px;
      margin-top: 0px;
      width: 150px;
      font-weight: 800;
    }
    h6 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 18px;
    }
    h3 {
      font-size: 18px;
    }
  }
}
</style>
