<template>
  <div class="FaQ">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Frequently Asked Questions</h4>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-10">
            <div class="panel-group" id="accordion">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse1"
                    >
                      What in particular is Medicare Supplement Insurance
                      (Medigap)?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="#ffffff"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse1" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    Medicare Supplement Insurance, also known as Medigap, is a
                    type of private health insurance that is designed to work
                    alongside Original Medicare. Medigap plans help cover the
                    out-of-pocket costs that Medicare beneficiaries may incur,
                    such as copayments, coinsurance, and deductibles.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse2"
                    >
                      Who is eligible or qualifies for Medicare Supplement
                      Insurance?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="#ffffff"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse2" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    If you are over the age of 65 and enrolled in Original
                    Medicare Part B, you might be able to apply for Medigap
                    Coverage. You are eligible for a Medicare Supplement plan at
                    any point during the course of a calendar year, but within
                    your 6-month open enrollment period, you may get any
                    insurance provided in your state and receive guaranteed
                    coverage regardless of whether you have pre-existing health
                    conditions.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse3"
                    >
                      What exactly is Medicare Advantage plan, and for what
                      reason should you get one?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="#ffffff"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse3" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    Medicare Advantage plan is a private insurance plan that
                    provides the same coverage as Medicare Part A and Part B, as
                    well as additional benefits such as prescription drug
                    coverage and vision and dental care. Medicare Advantage has
                    many advantages over original Medicare, such as simple
                    coverage, an array of plan options, and savings over the
                    long term.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse4"
                    >
                      How Medicare Supplement Insurance works with Medicare?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="#ffffff"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse4" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    Medicare Supplement Insurance works alongside with Medicare
                    Part A and B. If you prefer to get a solo Part D
                    Prescription Drug coverage, Medicare Supplement will also
                    work with it. Medicare Supplement Insurance is not the same
                    as Medicare Advantage. You can choose between a Medicare
                    Advantage Plan and a Medicare Supplement Plan, instead of
                    both.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaQ',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.FaQ {
  // background-image: url('@/assets/faqBg.jpg');
  // background-color: #cccccc;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  background: #f5f5f5;
  padding-bottom: 60px;
  h4 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 60px;
    text-align: center;
    margin-bottom: 10px;
  }
  .panel-heading {
    // border-bottom: 2px solid #ffc93c;
    width: 100%;
    position: relative;
    background-color: #114d90;
    height: 60px;
    .active {
      right: 10px;
      top: 17px;
      background-color: #114d90;
      border-radius: 50%;
      border: 1px solid #ffffff;
      width: 26px;
      height: 26px;
      padding-left: 3px;
      padding-top: -2px;
      svg {
        display: block;
        padding-top: 0px;
      }
    }
    .panel-title a {
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 200%; /* 66.667% */
      padding-top: 15px;
      width: 100%;
      text-decoration: none;
      display: block;
      padding-left: 20px;
    }
  }
  .panel-body {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 139%; /* 27.8px */
    padding-top: 15px;
    padding-bottom: 30px;
    padding-left: 20px;
  }
  .panel-default {
    // border-bottom: 2px solid #ffc93c;
    margin-bottom: 20px;
    margin-top: 10px;
    border: 2px solid #114d90;
  }
}
.panel-heading .collapsed .active {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  position: absolute;
  float: right;
  transition-duration: 1s;
}

.panel-heading .active {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  float: right;
  transition-duration: 1s;
}
@media screen and (max-width: 992px) {
  .FaQ {
    h4 {
      font-size: 18px;
      margin-top: 20px;
    }
    .panel-heading {
      height: auto;
      margin-bottom: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
      .panel-title {
        padding-right: 35px;
        .active {
          margin-top: 10px;
        }
      }
      .panel-title a {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px; /* 66.667% */
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .panel-body {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 500px) {
  .FaQ {
    .panel-heading {
      .panel-title {
        .active {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
