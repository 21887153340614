<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h4>Medicare Part C</h4>
            <div class="row">
              <div class="col-12">
                <p calss="BPara">
                  Medicare Part C is a Medicare health plan offered by
                  Medicare-approved commercial insurance firms. It combines the
                  benefits of Medicare Parts A and B (hospital insurance) into a
                  single plan, frequently with extra coverage options such as
                  prescription medicines, vision, dentistry, and hearing.
                </p>
              </div>
            </div>
            <h4>The Benefits Of Medicare Part C:</h4>
            <div class="row">
              <div class="col-12">
                <div class="subUl">
                  <h5>1-Comprehensive Coverage:</h5>
                  <p calss="BPara">
                    One of the most significant benefits of Medicare Part C is
                    its extensive coverage. Medicare Part C plans provide a
                    broader range of benefits than Original Medicare, which only
                    covers hospital stays and medical services. Prescription
                    medicine coverage, preventive care, wellness programs, and
                    even additional benefits such as gym memberships or
                    transportation services can be included.
                  </p>
                  <h5>2-Cost Savings:</h5>
                  <p calss="BPara">
                    Medicare Part C programs are intended to save beneficiaries
                    money. A monthly premium for a Medicare Advantage plan is
                    often lower than the combined premiums for Medicare Part A
                    and Part B. Furthermore, depending on the plan you choose,
                    out-of-pocket expenditures for services such as doctor
                    visits, hospital stays, or prescription prescriptions may be
                    reduced with Medicare Part C.
                  </p>
                  <h5>3-Coordination of Care:</h5>
                  <p calss="BPara">
                    Care coordination is commonly included in Medicare Advantage
                    plans, which means that your healthcare requirements are
                    managed and coordinated by a primary care physician. This
                    can lead to a more efficient healthcare experience, with
                    improved communication among doctors, specialists, and other
                    healthcare providers. Care coordination seeks to ensure that
                    you receive appropriate care, reduce duplication of
                    services, and eliminate medical errors.
                  </p>
                  <h5>4-Extra Benefits:</h5>
                  <p calss="BPara">
                    In addition to the baseline coverage offered by Medicare
                    Part C, many programs provide additional benefits not
                    provided by Original Medicare. Vision and dental treatment,
                    hearing aids, fitness programs, over-the-counter
                    medications, and even non-emergency medical transportation
                    are examples. Having these extra benefits can improve your
                    general well-being and provide peace of mind that your
                    healthcare needs are being serviced more effectively.
                  </p>
                  <h5>5-Simplified Administration:</h5>
                  <p calss="BPara">
                    Medicare Part C combines all of your Medicare benefits into
                    a single plan. This means that instead of dealing with many
                    insurance cards, benefit descriptions, and billing
                    statements, you simply have to deal with one. This
                    simplified administration can help you manage your
                    healthcare costs, track claims, and understand your
                    coverage.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicarePartA',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subUl {
  margin-left: 20px;
}
ul {
  list-style-type: decimal;
  li {
    font-weight: 300;
    color: #0a172f;
    font-size: 24px;
    line-height: 180%;
  }
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  h5 {
    color: #114d90;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 16px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
