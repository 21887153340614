<template>
  <div class="header bg-light preHdeader">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- <span class="hideOnMobile">Speak to a Licensed Sales Agent!</span> -->
          <a class="aTell" href="tel:2012855358">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="29"
              viewBox="0 0 21 29"
              fill="none"
            >
              <path
                d="M17.6547 28.5413C14.9079 27.7589 12.3658 26.4004 10.0282 24.466C7.6907 22.5315 5.73771 20.299 4.16928 17.7685C2.60085 15.238 1.49267 12.5294 0.844747 9.64269C0.196822 6.75598 0.175565 3.97678 0.780975 1.30507C0.865596 0.932195 1.06387 0.657857 1.3758 0.482058C1.68773 0.306259 2.03533 0.27295 2.4186 0.38213L7.59275 1.85606C7.89085 1.94098 8.13586 2.11002 8.32777 2.36318C8.51968 2.61634 8.60402 2.89399 8.58079 3.19614L8.42396 7.78294C8.40073 8.08509 8.33521 8.34784 8.22739 8.57119C8.11958 8.79455 7.94922 8.97186 7.71631 9.10312L3.95907 11.2749C4.51489 13.0211 5.31543 14.7379 6.36071 16.4251C7.40598 18.1123 8.62962 19.6628 10.0316 21.0767L13.6968 19.0111C13.9307 18.8792 14.2128 18.8109 14.543 18.8062C14.8733 18.8015 15.1824 18.8617 15.4704 18.987L19.6806 21.1126C19.9646 21.2597 20.1777 21.4695 20.3199 21.742C20.462 22.0145 20.5003 22.2954 20.4347 22.5846L19.2923 27.6184C19.2077 27.9913 19.0094 28.2656 18.6975 28.4414C18.3856 28.6172 18.038 28.6505 17.6547 28.5413Z"
                fill="white"
              />
            </svg>
            (201)-285-5358
            <span id="ttola"
              >&nbsp;&nbsp;&nbsp;&nbsp;Talk To Our Liscenced Agent Today</span
            >
          </a>
        </div>
      </div>
    </div>
  </div>
  <nav
    class="navbar navbar-expand-lg sticky-top navbar-light bg-light headerBG"
  >
    <div class="container">
      <a class="navbar-brand" href="#" @click.prevent="goto('Home')"
        ><img src="@/assets/logo.webp"
      /></a>
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar1"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbar1">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a
              class="nav-link text"
              href="#"
              @click.prevent="goto('AboutMedicare')"
              >About Medicare</a
            >
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Get Insurance
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </li> -->
          <li class="nav-item active">
            <a
              class="nav-link text"
              href="#"
              @click.prevent="goto('GetYourQuote')"
              >Shop Medicare Plans</a
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Our Medicare Plans
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" @click.prevent="goto('PartA')" href="#"
                >Medicare Part A</a
              >
              <a class="dropdown-item" @click.prevent="goto('PartB')" href="#"
                >Medicare Part B</a
              >
              <a class="dropdown-item" @click.prevent="goto('PartC')" href="#"
                >Medicare Part C</a
              >
              <a class="dropdown-item" @click.prevent="goto('PartD')" href="#"
                >Medicare Part D</a
              >
              <a
                class="dropdown-item"
                @click.prevent="goto('MedicareAdvantage')"
                href="#"
                >Medicare Advantages</a
              >
              <a
                class="dropdown-item"
                @click.prevent="goto('MedigapPlan')"
                href="#"
                >Medigap Plan</a
              >
            </div>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link text" href="#" @click.prevent="goto('ContactUs')"
              >Contact Us</a
            >
          </li>
          <li class="nav-item">
            <a href="#" class="getQouteA">Get in Touch </a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
  <!--container-->
</template>

<script>
export default {
  name: 'MainHeader',
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.aTell {
  color: #ffffff !important;
  text-decoration: none;
}
.preHdeader {
  background: #114d90 !important;
  color: #fff;
  height: 40px;
  text-align: center;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  position: fixed;
  width: 100%;
  z-index: 100;
  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.sticky-top {
  top: 40px;
}
.headerBG {
  background: rgba(255, 255, 255, 1) !important;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  .navbar-brand {
    color: #242f9b;
    padding-top: 0px;
    padding-bottom: 0px;
    img {
      height: 65px;
      /*margin-top: -5px;*/
    }
  }
  .navbar-nav {
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      a {
        color: #114d90;
        transition-duration: 0.6s;
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif !important;
      }
      a:hover {
        color: #000000;
        transition-duration: 0.6s;
      }
      a::after {
        color: #ffffff;
        transition-duration: 0.6s;
        float: right;
        margin-right: 20px;
        margin-top: 10px;
      }
      .text {
        margin-top: 0px;
      }
    }
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    margin-top: 26px;
  }

  .dropdown-item {
    color: #fff !important;
    height: 40px;
    line-height: 33px;
  }
  .dropdown-item:hover {
    background-color: #00000040;
    color: rgba(255, 201, 60, 1) !important;
    transition-duration: 0.5s;
  }
  .getQouteA {
    border-radius: 5px;
    border: 3px solid #292341;
    padding: 8px 16px;
    color: #ffffff !important;
    text-decoration: none;
    background-color: #292341 !important;
    margin-top: 7px;
  }
  .getQouteA:hover {
    box-shadow: rgba(149, 157, 165, 0.5) 0px 4px 12px;
    color: #ecf6fd !important;
  }
}
.homeSlide1 {
  background: #d9d9d9;
  .leftSide {
    h2 {
      color: #242f9b;
      font-family: 'Open Sans', sans-serif !important;
      font-size: 72px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2),
        0px -4px 5px rgba(255, 255, 255, 0.2);
      margin-top: 60px;
      animation: 0.5s ease-in 0s 1 slideInFromLeft;
    }
    h5 {
      color: #fff;
      font-family: 'Open Sans', sans-serif !important;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
      animation: 0.7s ease-in 0s 1 slideInFromLeft;
    }
    h6 {
      color: #242f9b;
      font-family: 'Open Sans', sans-serif !important;
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      animation: 0.9s ease-in 0s 1 slideInFromLeft;
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 130%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #292341;
  color: #fff;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
@media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1200px) {
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
      }
      h5 {
        font-size: 32px;
      }
      h6 {
        font-size: 24px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      justify-content: end;
      .nav-item {
        width: 25%;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #114d90 !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
#ttola {
}
@media screen and (max-width: 992px) {
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #292341 !important;
  }
  #ttola {
    display: none;
  }

  .navbar-collapse {
    padding-left: 10px;
    margin-top: 15px;
    /* max-width: 250px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end; */
    ul {
      width: 250px;
      float: right;
      background-color: #114d90;
      padding-left: 10px;
    }
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #ffffff !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .dropdown-menu {
    background-color: #00000011;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .homeSlide1 {
    h2 {
      font-size: 48px;
    }
    h5 {
      font-size: 48px;
      width: 100%;
      text-align: center;
    }
    h6 {
      font-size: 32px;
    }
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h2 {
      font-size: 32px;
      margin-top: 80px;
    }
    h5 {
      font-size: 32px;
      width: 100%;
      text-align: center;
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
  .headerBG {
    .navbar-brand {
      color: #242f9b;
      img {
        height: 65px;
      }
    }
  }
}
</style>
