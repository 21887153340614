<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h4>Is Medicare Part C Right For You?</h4>
            <div class="row">
              <div class="col-12">
                <p calss="BPara">
                  While Medicare Part C provides many benefits, it is vital to
                  consider whether it is the best option for your unique
                  circumstances. Here are a few things to think about:
                </p>
                <div class="subUl">
                  <h5>Coverage Requirements:</h5>
                  <p>
                    Examine your healthcare requirements to ensure that the
                    Medicare Part C plan you choose covers the services and
                    prescriptions you require.
                  </p>
                  <h5>Citizenship:</h5>
                  <p>
                    You must be a United States citizen or a legal permanent
                    resident who has lived in the country for at least five
                    years in a row.
                  </p>
                  <h5>Considerations for the Network:</h5>
                  <p>
                    Medicare Advantage plans frequently have a network of
                    doctors, hospitals, and other healthcare providers. Make
                    certain that the plan you select has your preferred
                    healthcare providers in its network.
                  </p>
                  <h5>Cost Comparison:</h5>
                  <p>
                    To identify the most cost-effective alternative, compare the
                    premiums, deductibles, copayments, and coinsurance of
                    Medicare Part C plans with your projected healthcare
                    expenses.
                  </p>
                  <h5>Prescription Drug Coverage:</h5>
                  <p>
                    If you regularly use prescription medications, make sure
                    that the Medicare Part C plan you select provides enough
                    prescription drug coverage.
                  </p>
                  <h5>Flexibility:</h5>
                  <p>
                    Consider if you prefer the freedom of Original Medicare,
                    which allows you to see any doctor or specialist who accepts
                    Medicare, or the network constraints of a Medicare Advantage
                    plan.
                  </p>
                  <p>
                    Medicare Part C, also known as Medicare Advantage, is a
                    comprehensive and cost-effective alternative to Original
                    Medicare. Medicare Part C plans attempt to improve
                    subscribers' overall healthcare experience by providing
                    additional benefits, care coordination, and streamlined
                    administration. However, it is critical to assess your
                    unique needs and compare different plans before deciding on
                    Medicare Part C.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicarePartC',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subUl {
  margin-left: 20px;
}
ul {
  list-style-type: decimal;
  li {
    font-weight: 300;
    color: #0a172f;
    font-size: 24px;
    line-height: 180%;
  }
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  h5 {
    color: #114d90;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
