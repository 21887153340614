<template>
  <div class="GameChanger">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="mainHeading">HOW IT WORKS RIGHT HERE</h3>
            <div class="row">
              <div class="col-lg-4">
                <div class="zCards">
                  <img src="@/assets/g1.png" />
                  <h5>Enter Zip Code</h5>
                  <p>
                    Enter your ZIP code to learn about potential Medicare
                    insurance discounts
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="zCards">
                  <img src="@/assets/g2.png" />
                  <h5>Compare Plans</h5>
                  <p>
                    Don't put off comparing Medicare plans and signing up for
                    the finest one in your area
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="zCards">
                  <img src="@/assets/g3.png" />
                  <h5>Get A Quote</h5>
                  <p>Get a free Medicare coverage estimate right away.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartToday',
  props: {
    msg: String,
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.GameChanger {
  background: #ffffff;
  padding-bottom: 20px;
  padding-top: 40px;
}
.mainHeading {
  color: #114d90;
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.zCards {
  border-radius: 21px;
  background: rgba(17, 77, 144, 0.22);
  box-shadow: 0px 4px 27px 6px rgba(3, 33, 99, 0.13) inset;
  padding: 20px;
  margin: 10px;
  margin-top: 50px;
  img {
    width: 80px;
    margin: 0 auto;
    display: block;
    margin-top: -60px;
  }
  h5 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 20px;
  }
  p {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    min-height: 90px;
  }
}
p {
  margin-bottom: 0px;
}
@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 24px;
  }
  .zCards {
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
