<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- <h4>Is Medicare Part C Right For You?</h4> -->
            <div class="row">
              <div class="col-12">
                <p calss="BPara">
                  Each form of Medicare Advantage plan is distinct from the
                  others and offers members a different set of guidelines,
                  information, coverage, and other things. Private insurance
                  policies known as Medicare Advantage plans fill up any gaps in
                  Medicare coverage. Do not confuse these with Medigap plans,
                  despite the fact that they have some key distinctions.
                </p>
                <p calss="BPara">
                  You must first enroll in Original Medicare (Medicare Parts A
                  and B) in order to be eligible for Medicare Advantage
                  enrollment. Review each Medicare Advantage plan that is
                  offered in your area after that. When you sign up for a
                  Medicare Advantage plan, it's crucial to comprehend the costs
                  that are not covered by the plan
                </p>
                <h4>(HMO) Health Maintenance Organization:</h4>
                <p calss="BPara">
                  In a Medicare Advantage plan known as a "Health Maintenance
                  Organization," you have to choose a primary care physician
                  that will handle your care, adhere to a network of hospitals
                  and physicians, and obtain recommendations before seeing a
                  specialist. If you have a Medicare HMO plan, you must see
                  providers who are part of the plan's network to be reimbursed
                  for medical costs.
                </p>
                <p calss="BPara">
                  You may be able to access care from an out-of-network
                  physician under some HMO plans. However, the price will
                  increase, or in rare situations, you'll pay the full amount.
                  You can use your plan's HMO-POS option if it permits you to
                  receive benefits from sources outside of your network. Before
                  enrolling in coverage, you should carefully go through all the
                  features of your plan. Your plan informs you if your doctor or
                  other healthcare provider quits the network. You will have the
                  chance of choosing another doctor from the program after being
                  informed.
                </p>
                <h4>(PPO) Preferred Provider Organization:</h4>
                <p calss="BPara">
                  The Preferred Provider Organization is one sort of Medicare
                  Advantage plan that is widely used. If a doctor, hospital, or
                  other healthcare provider is in your Medicare Advantage PPO
                  plan's network, you will pay less when you visit them. But
                  you'll also get benefits from outside your network, although
                  at a greater out-of-pocket cost.
                </p>
                <p calss="BPara">
                  Every PPO plan gives you the freedom to visit hospitals and
                  consult with experts or medical professionals who are not on
                  the plan's list. However, keep in mind that these services
                  have additional prices.
                </p>
                <p calss="BPara">
                  PPO plans don't force you to select a primary care physician
                  and allow you to visit any doctor of your choosing.
                  Additionally, references are not always necessary when seeking
                  out a specialist.
                </p>
                <h4>(SNP) Special Needs Plan:</h4>
                <p calss="BPara">
                  Medicare Advantage Special Needs Plans only allow people with
                  certain diseases or impairments, certain socioeconomic
                  backgrounds, or certain institutional classifications to apply
                  for coverage. In order to fulfil the medical requirements of
                  the group they serve, these plans modify doctor options,
                  coverage, and prescription formularies.
                </p>
                <p calss="BPara">
                  SNP coverage includes specialists to treat the qualifying
                  conditions of their members. You should seek out the services
                  of medical professionals in your SNP network for the finest
                  care and most affordable prices. Here are the few requirements
                  for SNPS’s
                </p>
                <ul>
                  <li>
                    Medicare Part D prescription medication benefits must be
                    included in the plan.
                  </li>
                  <li>
                    To help with healthcare concerns, members must have a
                    primary care physician or a care coordinator.
                  </li>
                  <li>
                    Before seeing a specialist, members must obtain a
                    prescription from their physician.
                  </li>
                  <li>
                    Pelvic exams, in-network Pap testing, and yearly mammography
                    screenings are all covered at least every two years.
                  </li>
                </ul>
                <h4>(PFFS) Private Fee-For-Service:</h4>
                <p calss="BPara">
                  Private Fee-for-Service (PFFS) plans for Medicare Advantage
                  are still an appealing option among specific Medicare
                  participants even though they are less common than HMO or PPO
                  plans. PFFS plans for Medicare Advantage are unique from other
                  Medicare Advantage plan types. Any Medicare doctor or facility
                  that accepts the rules of the plan's payment and provides
                  treatment can provide healthcare to beneficiaries.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicarePartC',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subUl {
  margin-left: 20px;
}
ul {
  list-style-type: decimal;
  li {
    font-weight: 300;
    color: #0a172f;
    font-size: 24px;
    line-height: 180%;
  }
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  h5 {
    color: #114d90;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 16px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
