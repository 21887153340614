<template>
  <div class="GameChanger">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <h3 class="mainHeading">Testimonials</h3>
          <p>
            How do customers feel about their new deep affection with Ever
            Medicare, and what are their views!
          </p>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row whatWeDoC">
              <div class="col-1 cPointer middleAlign" @click="changeSlide()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#070d59"
                  class="bi bi-chevron-compact-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223"
                  />
                </svg>
              </div>
              <div class="col-10">
                <div class="row tgheight">
                  <TransitionGroup name="list2" tag="div">
                    <div
                      class="col-lg-4 blogs"
                      v-for="p in ourPhilosphyC"
                      :key="p"
                    >
                      <div class="serviceCard middleAlign2">
                        <img :src="p.img" />
                        <img src="@/assets/star.png" />
                        <h5>{{ p.heading }}</h5>
                        <p>{{ p.desc }}</p>
                      </div>
                    </div>
                  </TransitionGroup>
                </div>
              </div>
              <div class="col-1 cPointer middleAlign" @click="changeSlide()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#070d59"
                  class="bi bi-chevron-compact-rights"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestimonialSection',
  data() {
    return {
      ourPhilosphyC: [],
      currnetSlideNumber: 'One',
      ourPhilosphy: [
        {
          img: require('@/assets/Avatar1.png'),
          heading: 'Dakotas Olive',
          desc: 'We were puzzled and discouraged about the costs associated with healthcare after hours spent online researching Medicare options and costs. A friend recommended Ever Medicare as a helpful resource for our queries. I had a great time discussing with him and I highly recommend them',
        },
        {
          img: require('@/assets/Avatar2.png'),
          heading: 'Graica Miller',
          desc: 'I was quite confused by all of the complex Medicare Supplement Plans, but Ever Health managed to find the right one for me which met both what I needed and my budget. You are all great!',
        },
        {
          img: require('@/assets/Avatar3.png'),
          heading: 'Dakotas Olive',
          desc: 'I have been using Medicare Supplement Insurance for several years now, and I can honestly say that it has been a lifesaver for me. The peace of mind that comes with knowing I am covered for all my medical expenses is priceless. I highly recommend this insurance to anyone who is eligible for it.',
        },
        {
          img: require('@/assets/Avatar2.png'),
          heading: 'Graica Miller',
          desc: 'I was quite confused by all of the complex Medicare Supplement Plans, but Ever Health managed to find the right one for me which met both what I needed and my budget. You are all great!',
        },
      ],
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    var gthis = this;
    setInterval(function () {
      gthis.changeSlide();
    }, 5000);
    gthis.changeSlide();
    //gthis.ourPhilosphyC = gthis.ourPhilosphy;
  },
  methods: {
    changeSlide() {
      let firstIndex = this.ourPhilosphy[0];
      this.ourPhilosphy.shift();
      this.ourPhilosphy.push(firstIndex);
      this.ourPhilosphyC = [];
      this.ourPhilosphyC.push(this.ourPhilosphy[0]);
      this.ourPhilosphyC.push(this.ourPhilosphy[1]);
      this.ourPhilosphyC.push(this.ourPhilosphy[2]);

      //console.log(this.currnetSlideNumber);
      //this.currnetSlideNumber = 'One';
      // if (this.currnetSlideNumber == 'One') {
      //   this.ourPhilosphyC = [
      //     {
      //       img: require('@/assets/sc3.png'),
      //       heading: 'Growth & Scalability',
      //       desc: 'Our world-class team of expert developers provides services on a large scale and around the clock. We perform tasks with precision using repeatable, scalable, and established techniques for higher efficiency and effectivity.',
      //     },
      //     {
      //       img: require('@/assets/sc4.png'),
      //       heading: ' Cost- Effective',
      //       desc: 'Our talent will allow you to streamline your business processes make them more efficient at lower costs. Our talent will allow you to streamline your business processes make them more efficient at lower costs.',
      //     },
      //     {
      //       img: require('@/assets/sc4.png'),
      //       heading: ' Cost- Effective',
      //       desc: 'Our talent will allow you to streamline your business processes make them more efficient at lower costs. Our talent will allow you to streamline your business processes make them more efficient at lower costs.',
      //     },
      //   ];
      //   this.currnetSlideNumber = 'Two';
      // } else {
      //   this.ourPhilosphyC = [
      //     {
      //       img: require('@/assets/sc1.png'),
      //       heading: 'Advanced Data Privacy',
      //       desc: 'We determine how minimal code development can have a substantial impact on the development of business apps. We create projects by writing targeted minimal code applications.',
      //     },
      //     {
      //       img: require('@/assets/sc2.png'),
      //       heading: 'BPO',
      //       desc: 'We automate with our comprehensive set of cloud computing infrastructure, application services, application development, and automated solutions, we deliver IT  support fast and affordably.',
      //     },
      //     {
      //       img: require('@/assets/sc4.png'),
      //       heading: ' Cost- Effective',
      //       desc: 'Our talent will allow you to streamline your business processes make them more efficient at lower costs. Our talent will allow you to streamline your business processes make them more efficient at lower costs.',
      //     },
      //   ];
      //   this.currnetSlideNumber = 'One';
      // }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list2 {
  width: 100%;
}
.list2-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}
.list2-leave-active {
  transition: all 0.3s ease;
}
.list2-enter-from {
  opacity: 0;
  transform: translateX(300px);
  height: 0px;
}
.list2-leave-to {
  opacity: 0;
  transform: translateX(-300px);
  height: 0;
}
.mainHeading {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
p {
  color: #fffefe;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-align: center;
}
.serviceCard {
  box-shadow: 0px 4px 27px 6px rgba(3, 33, 99, 0.13) inset;
  padding: 20px;
  margin: 10px;
  margin-top: 30px;
  border-radius: 8px;
  background: #e0e5ea;
  img {
    width: 100px;
    margin: 0 auto;
    display: block;
    margin-top: 10px;
  }
  h5 {
    color: #032163;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 20px;
  }
  p {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    text-align: center;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
// .middleAlign2 {
//   display: flex;
//   justify-content: center;
//   flex-direction: column; /* <--- I added this */
//   align-items: center; /* <--- Change here */
// }
// .mainPara {
//   color: #070d59;
//   font-family: Inter;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 26px; /* 162.5% */
// }
.middleAlign {
  display: flex;
  justify-content: center;
  flex-direction: row; /* <--- I added this */
  align-items: center; /* <--- Change here */
  padding-right: 0px;
  padding-left: 0px;
  svg {
    width: 24px;
    height: 24px;
    background-color: #ffffff99;
    border-radius: 50%;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 5px;
  }
}
// .middleAlign2 {
//   display: flex;
//   justify-content: center;
//   flex-direction: column; /* <--- I added this */
//   align-items: center; /* <--- Change here */
// }
// .middleAlign2 div {
//   display: flex;
//   flex-direction: column;
// }
// .whatWeDoC {
//   background-color: #ffffff;
//   margin-top: 20px;
//   padding: 40px;
//   padding-left: 10px;
//   padding-right: 10px;
//   margin-bottom: 40px;
// }
.GameChanger {
  background: radial-gradient(
    515.53% 190.41% at 3.16% -3.29%,
    #1d5696 0%,
    rgba(29, 86, 150, 0) 100%
  );
  padding-bottom: 40px;
  padding-top: 40px;
  overflow-x: hidden;
}
.blogs {
  float: left;
}
@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 24px;
  }
  .blogs:nth-child(2) {
    display: none;
  }
  .blogs:nth-child(3) {
    display: none;
  }
  p {
    font-size: 16px;
    text-align: left;
  }
}
</style>
