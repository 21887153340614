<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-lg-5">
            <h3>Don't Wait- Get in touch with Us Today</h3>
            <p>To discuss your Medicare insurance options.</p>
          </div>
          <div class="col-lg-5">
            <div class="row mTop">
              <div class="col-3">
                <a class="aTell" href="tel:2012855358">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="99"
                    height="99"
                    viewBox="0 0 99 99"
                    fill="none"
                  >
                    <ellipse
                      cx="49.5"
                      cy="49.5"
                      rx="49.5"
                      ry="49.5"
                      fill="#114D90"
                    />
                    <path
                      d="M72.0833 74.1346C66.1111 74.1346 60.2898 72.7995 54.6194 70.1294C48.9491 67.4593 43.9259 63.9313 39.55 59.5454C35.1741 55.1596 31.6556 50.1229 28.9944 44.4354C26.3333 38.7479 25.0019 32.911 25 26.9245C25 26.0889 25.2778 25.3926 25.8333 24.8356C26.3889 24.2785 27.0833 24 27.9167 24H39.1667C39.8148 24 40.3935 24.2089 40.9028 24.6267C41.412 25.0445 41.713 25.5783 41.8056 26.2282L43.6111 35.9766C43.7037 36.6265 43.6917 37.2188 43.575 37.7536C43.4583 38.2884 43.1926 38.7637 42.7778 39.1796L36.1111 46.0035C38.0556 49.3458 40.4861 52.4792 43.4028 55.4037C46.3194 58.3283 49.537 60.8582 53.0556 62.9936L59.5833 56.4482C60 56.0304 60.5444 55.7175 61.2167 55.5096C61.8889 55.3016 62.5481 55.2431 63.1944 55.3341L72.7778 57.2838C73.4259 57.423 73.9583 57.7369 74.375 58.2252C74.7917 58.7136 75 59.2817 75 59.9298V71.2101C75 72.0456 74.7222 72.742 74.1667 73.299C73.6111 73.8561 72.9167 74.1346 72.0833 74.1346Z"
                      fill="#FFFEFE"
                    />
                  </svg>
                </a>
              </div>
              <div class="col-9">
                <a class="aTell" href="tel:2012855358">
                  <h6>Call Us Now</h6>
                  <h5>(201)-285-5358</h5>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-1"></div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DontWait',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.aTell {
  text-decoration: none;
  h5 {
    color: #114d90;
    margin-top: 0px;
  }
}
.mTop {
  padding-top: 5px;
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  background-image: url('@/assets/dontwaitbg.jpg');
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
  padding-top: 40px;
  h3 {
    color: #fffefe;
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 142.2%; /* 51.192px */
    letter-spacing: -0.18px;
  }
  h4 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
  }
  h6 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 126%;
  }
  p {
    color: #114d90 !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 139.7%; /* 27.94px */
    letter-spacing: 0.4px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 992px) {
  .RightDeals {
    background: #f5f5f5;
    background-image: url('@/assets/dontwm.jpg');
    background-size: cover;
    background-position: right;
  }
  .mainHeading {
    font-size: 32px;
  }
  .mTop {
    width: 300px;
    padding-top: 0px;
    svg {
      width: 60px;
      height: 60px;
    }
    h6 {
      font-size: 24px;
      line-height: 100%;
      color: white;
    }
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      color: white !important;
    }
  }
  .aTell {
    h5 {
      color: white;
    }
  }
}
</style>
