<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h4>ABOUT MEDICARE COVERAGE</h4>
            <div class="row">
              <div class="col-12">
                <p calss="BPara">
                  Medicare is the federal health insurance program for people
                  aged 65 and above, and under 65 with End-Stage Renal Disease
                  (ESRD) or certain disabilities. Enrolling in the right
                  Medicare plan is an important decision to make. Your
                  lifestyle, budget, and health are key considerations. You’ve
                  worked hard to get to Medicare and now you want the freedom to
                  pursue your interests without worrying about health insurance.
                  That’s where we come in. As an independent agency the pros at
                  Evermedicare will help you understand your Medicare coverage
                  options and provide FREE advice so you can feel confident in
                  your decision!
                </p>
                <!-- <p calss="BPara">
                  We have a staff of knowledgeable, licensed agents available to
                  address your inquiries.
                </p>
                <p calss="BPara">
                  We provide knowledgeable guidance on Medicare Parts A, B, C,
                  and D.
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <ComparePlans />
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
import ComparePlans from '@/components/ComparePlans.vue';
export default {
  name: 'MedicareCoverage',
  components: {
    ComparePlans,
  },
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.aTell {
  color: #ffffff !important;
  text-decoration: none;
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 180px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h4 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 20px;
    }
    h5 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-weight: 800;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .bllutButton {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 540px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h4 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 20px;
    }
    h5 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-weight: 800;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .blueCard {
    padding: 10px;
    padding-top: 60px;
    padding-bottom: 40px;
    .bllutButton {
      font-size: 16px !important;
      padding: 7px 20px;
      margin-top: 20px;
      width: 170px;
      font-weight: 800;
    }
    .RedButton {
      font-size: 16px !important;
      padding: 7px 20px;
      margin-top: 0px;
      width: 150px;
      font-weight: 800;
    }
    h6 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 18px;
    }
    h3 {
      font-size: 18px;
    }
  }
}
</style>
