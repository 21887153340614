<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h4>Medicare Part D</h4>
            <div class="row">
              <div class="col-12">
                <p calss="BPara">
                  Medicare Part D is a government program that provides Medicare
                  recipients with prescription drug coverage. It was first made
                  available to all Medicare enrollees in 2006, regardless of
                  income or health status. The program is handled by
                  Medicare-approved private insurance companies and provides a
                  variety of prescription drug plans (PDPs) to choose from.
                </p>
                <p calss="BPara">
                  Once you have your Medicare (Part A and Part B) plan, you can
                  choose a private carrier for your Part D coverage.
                </p>
                <p calss="BPara">
                  After enrolling in Part D, you will have access to your
                  carrier's pharmacy network.
                </p>
                <p calss="BPara">
                  You can change from Original Medicare to Medicare Advantage
                  while maintaining Part D coverage.
                </p>
              </div>
            </div>
            <h4>What's Covered In Medicare PartD</h4>
            <p calss="BPara">
              Prescription medications are covered by Medicare Part D. Each
              private plan offered via Part D must meet Medicare's basic level
              of coverage. Because of the regulations, most plans have large
              formularies and the capacity to prescribe medications for a wide
              range of conditions. Private insurance companies have complete
              control over the prescriptions they cover as well as the "tiers"
              that impact the cost.
            </p>
            <p calss="BPara">
              Medicare Part D is divided into several coverage periods, each
              with its own cost-sharing system. Understanding these stages can
              help recipients better manage their prescription drug spending.
              Let's go over each stage:
            </p>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicarePartA',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subUl {
  margin-left: 20px;
}
ul {
  list-style-type: decimal;
  li {
    font-weight: 300;
    color: #0a172f;
    font-size: 24px;
    line-height: 180%;
  }
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  h5 {
    color: #114d90;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 16px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
