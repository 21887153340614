<template>
  <div class="GameChanger">
    <div class="container-flued">
      <div class="container BlueBG">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="mainHeading">Our Most Trusted Carriers</h3>
            <div class="row">
              <!-- <div class="col-lg-2 col-md-4 col-sm-6 col-6">
                <img src="@/assets/c1.png" class="cimg" />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6 col-6">
                <img src="@/assets/c2.png" class="cimg" />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6 col-6">
                <img src="@/assets/c7.png" class="cimg" />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6 col-6">
                <img src="@/assets/c4.png" class="cimg" />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6 col-6">
                <img src="@/assets/c5.png" class="cimg" />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6 col-6">
                <img src="@/assets/c6.png" class="cimg" />
              </div> -->
              <div class="marquee">
                <div class="marquee-content">
                  <div class="marquee-item">
                    <img src="@/assets/c1.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c2.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c3.png" alt="" />
                  </div>
                  <div class="marquee-item">
                    <img src="@/assets/c4.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c5.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c6.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c7.png" alt="" />
                  </div>
                  <div class="marquee-item">
                    <img src="@/assets/c1.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c2.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c3.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c4.png" alt="" />
                  </div>
                  <div class="marquee-item">
                    <img src="@/assets/c5.png" alt="" />
                  </div>
                  <div class="marquee-item">
                    <img src="@/assets/c6.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c7.png" alt="" />
                  </div>

                  <div class="marquee-item">
                    <img src="@/assets/c1.png" alt="" />
                  </div>
                  <div class="marquee-item">
                    <img src="@/assets/c2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrustedCarriers',
  props: {
    msg: String,
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.marquee {
  /*   overflow: hidden; */
  overflow: hidden;
}

.marquee-content {
  display: flex;
  animation: scrolling 90s linear infinite;
}

.marquee-item {
  flex: 0 0 200px;
  margin: 0 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 0px;
  /*   flex: 0 0 20vw; */
  /*   margin: 0 2vw; */
}
.marquee-content :nth-child(6) {
  padding: 0px;
  margin-top: -30px;
  flex: 0 0 250px;
}
.marquee-content :nth-child(13) {
  padding: 0px;
  margin-top: -30px;
  flex: 0 0 250px;
}

.marquee-item img {
  display: block;
  width: 100%;
  /*   padding: 0 20px; */
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translatex(-2400px);
  }
  100% {
    transform: translateX(0);
  }
}
.GameChanger {
  background: #ffffff;
  padding-bottom: 0px;
  padding-top: 0px;
}
.BlueBG {
  background: #114d90;
  margin-top: -40px;
  padding-bottom: 10px;
  z-index: 2;
  display: block;
  position: relative;
}
.cimg {
  width: 100%;
  padding: 20px;
  padding-top: 0px;
}
.mainHeading {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 992px) {
  .marquee-content {
    display: flex;
    animation: scrolling2 90s linear infinite;
  }
  @keyframes scrolling2 {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translatex(-1400px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .marquee-content :nth-child(6) {
    flex: 0 0 150px;
    margin-top: -10px;
  }
  .marquee-content :nth-child(13) {
    flex: 0 0 150px;
    margin-top: -10px;
  }
  .mainHeading {
    font-size: 18px;
    margin-bottom: 0px;
  }
  .marquee-item {
    flex: 0 0 100px;
    padding: 5px;
    padding-bottom: 10px;
  }
  .marquee-item img {
    width: 100%;
  }
}
</style>
