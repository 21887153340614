<template>
  <div class="Footer">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div class="logo"><img src="@/assets/logoF.png" /></div>
            <p>
              EverMedicare has professionally trained and qualified Medicare
              agents who specialize in Medicare Supplement, Medicare Advantage,
              and Prescription Drug plans. By requesting a quote, you agree to
              be contacted by a licensed sales representative about Medicare
              Advantage, Prescription Drug, and Medicare Supplement Insurance
              products.
            </p>
            <p>
              We have worked with over 20 top-rated insurance companies. Each
              company that renews your plan will choose the terms of your
              renewal. Plans are not available in all locations and are subject
              to underwriting; terms and conditions may apply. You fully agree
              to have one of our licensed insurance agents contact you when you
              provide information.
            </p>
            <p>
              Copyright © 2024 ALL LEADS PROVIDER LLC. All rights reserved.<br />
              evermedicare.com is owned and operated by All Leads Provider LLC.
            </p>
            <p>734 N .Broad St Elizabeth, NJ 07208</p>
          </div>
          <div class="col-lg-3">
            <div class="row">
              <div class="col-lg-12">
                <a href="#">Links</a>
                <div class="subFMenu">
                  <a href="#" @click.prevent="goto('AboutUs')">About Us</a>
                  <a href="#" @click.prevent="goto('AboutMedicare')"
                    >About Medicare</a
                  >
                  <a href="#" @click.prevent="goto('GetYourQuote')"
                    >Shop Medicare Plans</a
                  >
                  <a @click.prevent="goto('PartA')" href="#">Medicare Part A</a>
                  <a @click.prevent="goto('PartB')" href="#">Medicare Part B</a>
                  <a @click.prevent="goto('PartC')" href="#">Medicare Part C</a>
                  <a @click.prevent="goto('PartD')" href="#">Medicare Part D</a>
                  <a @click.prevent="goto('MedicareAdvantage')" href="#"
                    >Medicare Advantages</a
                  >
                  <a @click.prevent="goto('MedigapPlan')" href="#"
                    >Medigap Plan</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-flued">
      <div class="col-lg-12 allRights">
        ALL RIGHTS RESERVED |
        <span @click.prevent="goto('PrivacyPolicy')">PRIVACY POLICY</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterCom',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Footer {
  background: #114d90 !important;
  padding-top: 60px;
  .logo {
    color: #242f9b;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    img {
      width: 150px;
      margin-bottom: 10px;
    }
  }
  p {
    color: #ffffff;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 125% */
    margin-top: 0px;
  }
  a {
    width: 100%;
    text-align: left;
    text-decoration: none;
    display: block;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
  }
  .subFMenu {
    margin-top: 10px;
    margin-bottom: 5px;
    a {
      text-align: left;
      border-left: 2px solid #ffffff;
      margin-top: 5px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 175%;
    }
  }
  .allRights {
    color: #ffffff;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-top: 60px;
    background-color: #00000099;
    padding-top: 20px;
    padding-bottom: 20px;
    span {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 992px) {
  .Footer {
    padding-top: 20px;
    padding-bottom: 0px;
    p {
      width: 100%;
      font-size: 14px;
      line-height: normal;
    }
    a {
      text-align: left;
      margin-top: 10px;
    }
    .subFMenu {
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
