// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AboutMedicare from '@/views/AboutMedicare.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import GetYourQuote from '@/views/GetYourQuote.vue';
import PartA from '@/views/PartA.vue';
import PartB from '@/views/PartB.vue';
import PartC from '@/views/PartC.vue';
import PartD from '@/views/PartD.vue';
import MedicareAdvantage from '@/views/MedicareAdvantage.vue';
import MedigapPlan from '@/views/MedigapPlan.vue';
import AboutUs from '@/views/AboutUs.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/about-medicare',
    name: 'AboutMedicare',
    component: AboutMedicare,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/get-your-qoute',
    name: 'GetYourQuote',
    component: GetYourQuote,
  },
  {
    path: '/medicare-part-a',
    name: 'PartA',
    component: PartA,
  },
  {
    path: '/medicare-part-b',
    name: 'PartB',
    component: PartB,
  },
  {
    path: '/medicare-part-c',
    name: 'PartC',
    component: PartC,
  },
  {
    path: '/medicare-part-d',
    name: 'PartD',
    component: PartD,
  },
  {
    path: '/medicare-advantage',
    name: 'MedicareAdvantage',
    component: MedicareAdvantage,
  },
  {
    path: '/medigap-plan',
    name: 'MedigapPlan',
    component: MedigapPlan,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
];

const router = createRouter({ history: createWebHistory(), routes });
export default router;
