<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- <h4>Is Medicare Part C Right For You?</h4> -->
            <div class="row">
              <div class="col-12">
                <h5>Initial Deductible Phase:</h5>
                <p calss="BPara">
                  Beneficiaries are liable for paying a set amount as a
                  deductible before their plan coverage begins during this
                  period. The deductible amount may differ depending on the plan
                  selected.
                </p>
                <h5>Initial Coverage Phase:</h5>
                <p calss="BPara">
                  Beneficiaries start the initial coverage phase after the
                  deductible is reached. They pay a copayment or coinsurance for
                  each medicine during this term. The remaining costs are
                  covered by the plan until the total drug costs exceed a
                  certain threshold.
                </p>
                <h5>Coverage Gap (Donut Hole) Phase:</h5>
                <p calss="BPara">
                  Beneficiaries enter the coverage gap period whenever they
                  reach the aforementioned threshold. They are accountable for a
                  bigger percentage of the prescription expenditures during this
                  phase until they reach the catastrophic coverage threshold.
                </p>
                <h5>Catastrophic Coverage Phase:</h5>
                <p calss="BPara">
                  Beneficiaries enter the catastrophic coverage phase once they
                  have exceeded the catastrophic coverage threshold. They pay a
                  lesser amount or coinsurance for their prescription drugs for
                  the remainder of the year during this phase.
                </p>
                <p calss="BPara">
                  It's crucial to remember that not all beneficiaries will reach
                  the coverage gap or catastrophic coverage stages, as their
                  prescription medication expenditures will vary during the
                  year.
                </p>

                <h4>Medicare Part D Enrollment Process:</h4>
                <p calss="BPara">
                  Enrolling in Medicare Part D necessitates careful thought and
                  comprehension of the available options. The enrollment
                  procedure is explained in the subsequent steps:
                </p>
                <h5>Evaluate Your Medication Needs:</h5>
                <p calss="BPara">
                  It is vital to evaluate your current drugs and the associated
                  expenses before enrolling in a Medicare Part D plan. This will
                  assist you in determining the best plan for your requirements.
                </p>
                <h5>Compare Plans:</h5>
                <p calss="BPara">
                  There are a variety of Medicare Part D plans available, each
                  with its own formulary and pricing structure. Compare plans
                  using online tools or speak with a Medicare specialist to find
                  one that meets your individual needs.
                </p>
                <h5>Enroll During Open Enrollment Period:</h5>
                <p calss="BPara">
                  Each year, the open enrollment period for Medicare Part D runs
                  from October 15th through December 7th. During this time,
                  eligible individuals can enroll, switch plans, or modify their
                  current coverage.
                </p>
                <h5>Consider Late Enrollment Penalties:</h5>
                <p calss="BPara">
                  It is critical to enroll in a Medicare Part D plan as soon as
                  you are eligible, as late enrollment penalties may apply.
                  These fees are charged to the monthly premium for each month
                  that the individual might have gotten coverage but did not.
                </p>
                <h5>Seek Help If Necessary:</h5>
                <p calss="BPara">
                  Navigating the nuances of Medicare Part D can be difficult.
                  Consider contacting a registered insurance agent or a Medicare
                  representative if you need assistance with the enrollment
                  process or have specific queries.
                </p>
                <p calss="BPara">
                  Medicare Part D is critical in ensuring that Medicare
                  beneficiaries have access to cheap prescription drugs.
                  Beneficiaries can make informed decisions about their
                  healthcare requirements if they understand the coverage,
                  enrollment procedure, and commonly asked questions about
                  Medicare Part D. Whether it's navigating the various coverage
                  phases or choosing the best plan for you, Medicare Part D
                  offers comprehensive support for prescription medication
                  needs, encouraging overall well-being and peace of mind.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicarePartC',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subUl {
  margin-left: 20px;
}
ul {
  list-style-type: decimal;
  li {
    font-weight: 300;
    color: #0a172f;
    font-size: 24px;
    line-height: 180%;
  }
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  h5 {
    color: #114d90;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 16px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
