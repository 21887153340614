<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h4>Medicare Part B Eligibility:</h4>
            <div class="row">
              <div class="col-12">
                <p calss="BPara">
                  Individuals must meet the following conditions to be eligible
                  for Medicare Part B:
                </p>
                <div class="subUl">
                  <h5>Age:</h5>
                  <p>You must be at least 65 years old.</p>
                  <h5>Citizenship:</h5>
                  <p>
                    You must be a United States citizen or a legal permanent
                    resident who has lived in the country for at least five
                    years in a row.
                  </p>
                  <h5>Part A enrollment:</h5>
                  <p>You must already be enrolled in Medicare Part A.</p>
                  <h5>Payment of Premiums:</h5>
                  <p>
                    Medicare Part B requires you to pay monthly premiums that
                    are calculated based on your income.
                  </p>
                </div>
                <h4>What Does Medicare Part B Exclude?</h4>
                <p calss="BPara">
                  Medicare Part B only pays for certain treatments provided by
                  medical practitioners who accept Original Medicare coverage.
                  Unfortunately, Medicare Part B does not cover a number of
                  services. Medicare Part B excludes the following benefits:
                  dental, vision, hearing, and prescription medication coverage.
                  Furthermore, it excludes any medical services that are not
                  deemed medically required or preventive, such as cosmetic
                  surgery, as well as any medical services given by
                  non-Medicare-participating doctors. Furthermore, all inpatient
                  services are covered by Medicare Part A.
                </p>
                <h4>Medicare Part B Costs:</h4>
                <p calss="BPara">
                  Premiums, deductibles, and coinsurance are all part of
                  Medicare Part B expenditures. Here's a rundown of the major
                  costs:
                </p>
                <div class="subUl">
                  <ul>
                    <li>
                      When you enroll in this portion of Medicare, you are
                      liable for the costs associated with it, such as premiums.
                      The regular Medicare Part B premium in 2023 is $164.90 per
                      month.
                    </li>
                    <li>
                      Those with a greater income, on the other hand, will have
                      to pay a larger monthly premium. The Income Related
                      Monthly Adjustment Amount (IRMAA) is responsible for the
                      higher monthly premium. IRMAA is established by examining
                      at your annual income and determining your premium using a
                      sliding scale. Most Medicare recipients will have their
                      premium withdrawn automatically from their monthly Social
                      Security payments check. If you do not receive Social
                      Security benefits, Medicare will send you a quarterly fee.
                    </li>
                    <li>
                      Easy Pay is a Medicare online payment option that you can
                      access through your MyMedicare account. You can also pay
                      your quarterly subscription by mail if you choose.
                    </li>
                    <li>
                      In addition to the cost, your Medicare Part B coverage
                      includes an annual deductible and 20% coinsurance, which
                      you must pay out of pocket. The Medicare Part B deductible
                      in 2023 is $226.
                    </li>
                    <li>
                      Medicare will fund 80% of your Medicare Part B expenses
                      once you have met the annual deductible. Medicare
                      Supplement plans can fill this coverage gap if you wish to
                      reduce your Medicare Part B expenditures. Medicare
                      Supplement plans serve to lower out-of-pocket expenses and
                      are accepted wherever Original Medicare is accepted.
                    </li>
                    <li>
                      If you are a low-income recipient who is also qualified
                      for both Medicare and Medicaid, you may be eligible.
                    </li>
                  </ul>
                </div>
                <h4>Enrolling in Medicare Part B:</h4>
                <p calss="BPara">
                  Enrolling in Medicare Part B is a simple process. Here's how
                  you can begin:
                </p>
                <div class="subUl">
                  <ul>
                    <li>
                      Initial Enrollment Period (IEP): If you are turning 65 and
                      are not yet receiving Social Security payments, you can
                      enroll in Medicare Part B during the seven-month period
                      that begins three months before and ends three months
                      after your birthday month.
                    </li>
                    <li>
                      If you missed your IEP, you can enroll during the General
                      Enrollment Period (GEP), which runs from January 1st to
                      March 31st each year. Late enrollment, on the other hand,
                      may result in a permanent increase in rates.
                    </li>
                    <li>
                      Special Enrollment Period (SEP): Some people may be
                      eligible for a SEP if they have delayed enrolling due to
                      work or group health coverage.
                    </li>
                  </ul>
                </div>
                <h4>Medicare Part B Benefits:</h4>
                <p calss="BPara">
                  Medicare Part B provides a variety of benefits to ensure that
                  your healthcare needs are satisfied. Among the many advantages
                  are:
                </p>
                <div class="subUl">
                  <ul>
                    <li>
                      You will have access to a large network of healthcare
                      professionals, including doctors, specialists, and
                      hospitals.
                    </li>
                    <li>
                      Preventive care including as screenings, vaccines, and
                      yearly wellness visits are covered.
                    </li>
                    <li>
                      Part B's cost-sharing mechanism provides financial
                      protection against high medical costs.
                    </li>
                    <li>
                      The ability to seek medical treatment outside of your
                      immediate location, including while traveling within the
                      United States.
                    </li>
                    <li>
                      The ability to supplement your benefits with supplemental
                      coverage, such as Medicare Advantage or Medigap policies.
                    </li>
                    <li>
                      Part B of Medicare provides comprehensive coverage for a
                      wide range of medical services, supplies, and preventative
                      care. It is critical in ensuring that qualified folks
                      receive the appropriate healthcare. Understanding Medicare
                      Part B coverage and benefits is critical for making
                      informed decisions regarding your healthcare needs.
                      Medicare Part B protects the health of millions of
                      Americans by providing access to healthcare professionals,
                      preventive services, medical equipment, and other
                      services.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicarePartC',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subUl {
  margin-left: 20px;
}
ul {
  list-style-type: decimal;
  li {
    font-weight: 300;
    color: #0a172f;
    font-size: 16px;
    line-height: 180%;
  }
}
.blueCard {
  background-color: #114d90;
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
  }
  .bllutButton {
    color: #114d90;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: 1s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .RedButton {
    color: #fff;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    animation: 1.2s ease-in 0s 1 slideInFromLeft;
    position: relative;
    font-size: 30px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
      0px -4px 5px rgba(255, 255, 255, 0.2);
    border-radius: 13px;
    background: #f40f0f;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 40px;
    display: block;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
  }
  h6 {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blueCardP {
    color: #ffffff !important;
    font-size: 18px !important;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  h3 {
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }
}
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 60px;
  padding-top: 60px;
  h4 {
    color: #114d90;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  h5 {
    color: #114d90;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
    text-decoration: uppercase;
  }
  p {
    color: #0a172f !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: justify;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 32px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
