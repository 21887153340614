<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="mainHeading">Who We Are</h2>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-5">
            <img src="@/assets/oldisgold.jpg" class="fullWidthImg" />
          </div>
          <div class="col-lg-5">
            <h4>
              Navigating various plans, coverage, deductibles, and premiums can
              be challenging when it comes to insurance. Ever Medicare fills
              that gap.
            </h4>
            <div class="row">
              <div class="col-2">
                <img src="@/assets/bullets.png" class="bulllets" />
              </div>
              <div class="col-10">
                <p calss="BPara">
                  We are committed to assisting you in locating the top Medicare
                  Plans for your requirements
                </p>
                <p calss="BPara">
                  We have a staff of knowledgeable, licensed agents available to
                  address your inquiries.
                </p>
                <p calss="BPara">
                  We provide knowledgeable guidance on Medicare Part A, Part B,
                  Part C and also on Part D.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-1"></div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhatWeDo',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bulllets {
  margin-top: 30px;
}
.mainHeading {
  color: #114d90;
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #f5f5f5;
  padding-bottom: 40px;
  padding-top: 40px;
  h4 {
    color: #114d90;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
  }
  p {
    color: #114d90 !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 139.7%; /* 27.94px */
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHeading {
    font-size: 24px;
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 18px;
      margin-top: 20px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      margin-left: 10px;
    }
  }
}
@media screen and (max-width: 450px) {
  .RightDeals {
    p {
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
    }
  }
}
</style>
