<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3>Get In Touch With <br />Our Experts</h3>
          </div>
          <div class="col-lg-1">
            <a class="aTell" href="tel:+1 (929) 304-5521">
              <img src="@/assets/call.png" class="callImg" />
            </a>
          </div>
          <div class="col-lg-5 callText">
            <a class="aTell" href="tel:2012855358">
              <h4>Call Us Now</h4>
              <h4>(201)-285-5358</h4>
            </a>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetInTouch',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.aTell {
  color: #ffffff !important;
  text-decoration: none;
}
.bllutButton {
  color: #fff;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  animation: 1s ease-in 0s 1 slideInFromLeft;
  position: relative;
  font-size: 30px;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
    0px -4px 5px rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  background: #114d90;
  text-decoration: none;
  padding: 15px 30px;
  display: inline-block;
  margin-top: 40px;
}
.RedButton {
  color: #fff;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  animation: 1.2s ease-in 0s 1 slideInFromLeft;
  position: relative;
  font-size: 30px;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1),
    0px -4px 5px rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  background: #f40f0f;
  text-decoration: none;
  padding: 15px 30px;
  display: inline-block;
  margin-top: 40px;
}
.mTop {
  padding-top: 0px;
}
.bulllets {
  margin-top: 30px;
}
.callImg {
  margin-top: 15px;
}
.mainHeading {
  color: #114d90;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}
.fullWidthImg {
  width: 100%;
}
.callText {
  padding-top: 20px;
}
.RightDeals {
  background: #f5f5f5;
  background-image: url('@/assets/dontwaitbg.jpg');
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
  padding-top: 40px;
  h3 {
    color: #032163;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 142.2%; /* 51.192px */
    letter-spacing: -0.18px;
  }
  h4 {
    color: #032163;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 139.7%; /* 33.528px */
  }
  h6 {
    color: #114d90;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 240%;
  }
  p {
    color: white !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 139.7%; /* 27.94px */
    letter-spacing: 0.4px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 20px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    .callImg {
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .callImg {
    width: 80px !important;
    margin: 0 auto !important;
    display: block;
  }
  .bllutButton {
    margin-top: 0px;
  }
  .RedButton {
    margin-top: 10px;
  }
  .mainHeading {
    font-size: 32px;
  }
  .mTop {
    width: 300px;
    padding-top: 0px;
    svg {
      width: 50px;
      height: 50px;
    }
    h6 {
      font-size: 24px;
      line-height: 200%;
    }
  }
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 20px;
      text-align: center;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
@media screen and (max-width: 992px) {
  .RightDeals h3 {
    font-size: 24px;
    text-align: center;
  }
}
</style>
