<template>
  <div class="preloader" id="preloader">
    <div class="loader"></div>
  </div>
  <div class="fixedButtonDiv">
    <a href="tel:9293045521">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="29"
        viewBox="0 0 21 29"
        fill="none"
      >
        <path
          d="M17.6547 28.5413C14.9079 27.7589 12.3658 26.4004 10.0282 24.466C7.6907 22.5315 5.73771 20.299 4.16928 17.7685C2.60085 15.238 1.49267 12.5294 0.844747 9.64269C0.196822 6.75598 0.175565 3.97678 0.780975 1.30507C0.865596 0.932195 1.06387 0.657857 1.3758 0.482058C1.68773 0.306259 2.03533 0.27295 2.4186 0.38213L7.59275 1.85606C7.89085 1.94098 8.13586 2.11002 8.32777 2.36318C8.51968 2.61634 8.60402 2.89399 8.58079 3.19614L8.42396 7.78294C8.40073 8.08509 8.33521 8.34784 8.22739 8.57119C8.11958 8.79455 7.94922 8.97186 7.71631 9.10312L3.95907 11.2749C4.51489 13.0211 5.31543 14.7379 6.36071 16.4251C7.40598 18.1123 8.62962 19.6628 10.0316 21.0767L13.6968 19.0111C13.9307 18.8792 14.2128 18.8109 14.543 18.8062C14.8733 18.8015 15.1824 18.8617 15.4704 18.987L19.6806 21.1126C19.9646 21.2597 20.1777 21.4695 20.3199 21.742C20.462 22.0145 20.5003 22.2954 20.4347 22.5846L19.2923 27.6184C19.2077 27.9913 19.0094 28.2656 18.6975 28.4414C18.3856 28.6172 18.038 28.6505 17.6547 28.5413Z"
          fill="white"
        />
      </svg>
      Speak to Licensed Agent</a
    >
  </div>
  <MainHeader />
  <HomeSlider />
  <TrustedCarries />
  <StartToday />
  <WhatWeDo />
  <DontWait />
  <!-- <HomeTestimonials /> -->
  <TestimonialSection />
  <FaQ />
  <GetInstantQuote />
  <!-- <FeaturedArticle /> -->
  <WhyUs />
  <FooterCom />
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import HomeSlider from '@/components/Home/HomeSlider.vue';
import TrustedCarries from '@/components/Home/TrustedCarriers.vue';
import StartToday from '@/components/Home/StartToday.vue';
import DontWait from '@/components/Home/DontWait.vue';
import WhatWeDo from '@/components/Home/WhatWeDo.vue';
import TestimonialSection from '@/components/Home/TestimonialSection.vue';
import FooterCom from '@/components/FooterCom.vue';
// import HomeTestimonials from '@/components/Home/HomeTestimonials.vue';
import FaQ from '@/components/Home/FaQ.vue';
import GetInstantQuote from '@/components/Home/GetInstantQuote.vue';
import WhyUs from '@/components/Home/WhyUs.vue';

export default {
  name: 'GameChanger',
  components: {
    MainHeader,
    HomeSlider,
    TrustedCarries,
    StartToday,
    DontWait,
    WhatWeDo,
    FooterCom,
    // HomeTestimonials,
    FaQ,
    GetInstantQuote,
    TestimonialSection,
    WhyUs,
  },
  mounted() {},
};
// document.addEventListener(
//   'DOMContentLoaded',
//   function () {
//     document.getElementById('preloader').style.display = 'none';
//   },
//   false
// );
window.onload = function () {
  document.getElementById('preloader').style.display = 'none';
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
  font-family: 'Inter', sans-serif !important;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden !important;
}
#app {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  padding: 0px;
}
.preloader {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000aa;
  z-index: 9999;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 1;
  display: flex;
  margin: 0 auto;
  margin-top: 45vh;
}
.loader::before,
.loader::after {
  content: '';
  flex: 1;
  animation: l14 1.5s -0.25s infinite linear alternate both;
}
.loader::after {
  --s: -1, -1;
  animation-delay: 0.5s;
}
@keyframes l14 {
  0%,
  30% {
    transform: scale(var(--s, 1)) translate(0px) perspective(150px)
      rotateY(0deg);
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%, 0 75%, 0 25%);
    background: #f40f0f;
  }
  37.5% {
    transform: scale(var(--s, 1)) translate(-10px) perspective(150px)
      rotateX(0deg);
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%, 0 75%, 0 25%);
    background: #f40f0f;
  }
  50% {
    transform: scale(var(--s, 1)) translate(-10px) perspective(150px)
      rotateX(-90deg);
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%, 0 75%, 0 25%);
    background: #f40f0f;
    border-radius: 0;
  }
  50.01% {
    transform: scale(var(--s, 1)) translate(-10px) perspective(150px)
      rotateX(-90.1deg);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 25%);
    background: #114d90;
    border-radius: 100px 0 0 100px;
  }
  62.5% {
    transform: scale(var(--s, 1)) translate(-10px) perspective(150px)
      rotateX(-180deg);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 25%);
    background: #114d90;
    border-radius: 100px 0 0 100px;
  }
  70%,
  100% {
    transform: scale(var(--s, 1)) translate(0px) perspective(150px)
      rotateX(-180deg);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 25%);
    background: #114d90;
    border-radius: 100px 0 0 100px;
  }
}
</style>
